.year {
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0;
}

.month {
  padding: 5px 15px 20px 5px;
}

.month-name {
  color: black;
  font-weight: bold;
  margin-left: 10px;
  margin-bottom: 10px;
}

.day {
  display: inline-block;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
}


.date {
  width: 30px;
  height: 30px;
  background: white;
  border-radius: 50px;
  border: none;
  outline: none;
}

.date.in-month:hover {
  cursor: pointer;
  background: #ccc;
}

.today {
  background: #ddd;
}

.date.prev-month,
.date.next-month {
  color: lightgrey;
}