.topNav {
    height:44px;
    line-height:44px;
    background:#767676;
    color:#fff;
    position:fixed;
    z-index:1000;
    top:0px;
    left:0px;
    width:100%;
    font-size:16px;
    padding:0px 10px;
    font-weight:bold;
    text-align:right;

    .topNavButtons{

        margin-left:20px;
        float:right;

        .topNavButton {
            position:relative;
            top:-1px;
        }
    }
}