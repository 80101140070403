
.ruleGroup {
    padding:13px;
    border:1px solid rgba(34,36,38,.15);
    border-radius:.28571429rem;
    margin-top:13px;
}

.rule {
    margin-top:13px;
}

.filtersLeftMargin {
    margin-left:10px !important;
}
.filtersRightMargin {
    margin-right:10px !important;
}

.filtersCombinatorMargin {
    margin-top:10px !important;
}

.cardHolder .extra.content .ui.button {
    padding:.4rem !important;
    font-size:.78571429rem;
}