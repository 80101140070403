.formButtonHolder {
    margin:20px 0px;
}

.requiredLabel {
    position:relative;
    left:5px;
    bottom:1px;
    opacity:.7;
}

.editGroupHolder {
    overflow:visible !important;
    //max-height: 5000px !important;
    transition: max-height 0.3s ease-in-out !important;
    margin:13px 0px;
    box-sizing: border-box;
    padding:3px 1px 1px;

    &.collapsed {
        max-height: 52px !important;
        overflow:hidden !important;
    }
}

//code area vs semantic ui bug fix
.codearea textarea{
    padding:0 !important;
    min-height:0px !important;
    height:0px !important;
    min-width:0px !important;
    width:0px !important;
    margin:0px !important;
}