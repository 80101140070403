.groupHolder {
    overflow:hidden !important;
    //max-height: 5000px !important;
    transition: max-height 0.3s ease-in-out !important;
    margin:13px 0px;
    box-sizing: border-box;
    padding-top:3px;

    &.collapsed {
        max-height: 50px !important;
    }
}