.menuItem {
    height:30px;
    line-height:30px;
    padding:0px 20px;
    box-sizing:border-box;
    width:200px;
    text-align:left;
    color:#000;
    transition: .5s all;
    white-space:nowrap;

    span {
        padding-left:5px;
    }

    &.menuClosed {
        padding:0px 6px;
    }

    &.link {
        cursor:pointer;

        &:hover, &.active {
            background:#EFEFEF;
            font-weight:bold;
        }
    }
}

.childMenuItem {
    font-size:12px;
    padding-left:45px;
    overflow:hidden;
    transition: .5s all;
    height:20px;
    color:#000;
    cursor:pointer;
    &:hover, &.active {
        background:#EFEFEF;
        font-weight:bold;
    }

    &.menuClosed {
        height:0px;
    }
}