.refreshWarning {
    background-color:rgb(0, 0, 0);
    color:#fff;
    position:fixed;
    z-index:10000;
    bottom:50px;
    left:50%;
    margin-left:-40%;
    text-align:center;
    width:80%;
    font-size:16px;
    padding:20px;
    box-sizing: border-box;
    font-weight:bold;

    .buttonHolder {
        margin-top:20px;
    }
}