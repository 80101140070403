.leftNav {
    width:200px;
    background:#FFF;
    position:fixed;
    left:0px;
    z-index:1000;
    top:0px;
    height:100%;
    transition: .5s all;
    padding-top:10px;
    box-shadow: 4px 0 4px -3px #AAA;

    .openCloseLeftNav {
        right:-40px;
        top:8px;
        position:absolute;
        z-index:1;
        cursor:pointer;
        transition: .5s transform linear;

        i {
            margin:0px;
            box-shadow:none; 
            color:#111;
            background:#FFF;
        }
    }

    &.closed {
        width:30px;

        .openCloseLeftNav {
            transform: rotate(180deg);
        }
    }
    
    .loginInfo {
        width:100%;
        height:50px;
        text-align:center;
        margin-bottom:20px;
        transition: .5s all;
        padding-top:20px;
        
        .nameLabel {
            transition: .5s all;
        }
    }

    &.closed {
        .loginInfo {
            width:100%;
            height:0px;
            padding:0px;
            text-align:center;
            pointer-events:none;

            .nameLabel {
                opacity:0;
                height:0px;
            }
        }
    }

    .menu {
        overflow-y:scroll;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: 0px;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    &.closed {
        .menu {
            overflow:hidden;
        }
    }

    .logoHolder{
        width:80%;
        margin:0 auto;
        text-align:center;
        transition: .5s all;
        height:60px;
        background-repeat:no-repeat;
        background-size:contain;
        background-position:50%;
    }

    &.closed {
        .logoHolder{
            padding-top:30px;
        }
    }


    .poweredBy {
        position:absolute;
        bottom:10px;
        overflow:hidden;
        text-align:center;
        width:100%;
        
        img {
            display:inline-block;
            transition: .5s all;
            width:70px;
        }
    }

    &.closed {
        .poweredBy{
            width:100%;
            img {
                display:inline-block;
                width:140px;
                padding-left:2px;
            }
        }
    }
}

